import { Component, OnInit } from "@angular/core";
import { Loginservice } from "src/app/pages/login/login.service";

@Component({
    selector: 'web-layout',
    templateUrl: 'web-layout.component.html',
    styleUrls: ['web-layout.component.css']
})
export class WebLayoutComponent implements OnInit {

    constructor(private login:Loginservice){}

    ngOnInit(): void {
        
    }
}