import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ShotLockerService } from "../../shared/service/shot-locker.service";
import { GolferService } from "../golfer/golfer.service";
import { LockerRoomService } from "../locker-room/locker-room.service";
import { Loginservice } from "../login/login.service";

@Component({
  selector: "app-subscription",
  templateUrl: "subscription.component.html",
  styleUrls: ["subscription.component.css"],
})
export class SubscriptionComponent implements OnInit {
  userDetails;
  sub_discription;
  substartDate;
  subEndDate;
  status = null;
  token;
  today;
  paymentCard = false;

  constructor(
    private toastr: ToastrService,
    private locker: ShotLockerService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private golfer: GolferService,
    private login: Loginservice
  ) {}

  ngOnInit(): void {
    this.today = new Date();

    this.sub_discription = localStorage.getItem("description");
    this.substartDate = localStorage.getItem("started_on");
    this.subEndDate = localStorage.getItem("ended_on");
    this.status = localStorage.getItem("subscription_status");
    this.token = localStorage.getItem("access_token");
    this.getSubscriptionStatus()
    // this.checkPaymentmethod();
    

    if (this.token) {
      this.golfer.getProfileDetails().subscribe((res) => {
        console.log(res, "res of user");
        this.userDetails = res;
      });
    }
  }

  getSubscriptionStatus(){
    this.locker
    .sendRequest( "GET", "/api/subscription/check-status", null,null, null, null,false).subscribe((response) => {
      console.log(response, "subscription status");
      if (response.status ==200) {
        this.status = response.body.subscription_status;
        this.sub_discription =response.body.description
        this.substartDate =response.body.started_on
        this.subEndDate =response.body.ended_on
      }
    });
  }

  sub_type(sub_type, amount) {
    if (this.token) {
      if (true) {
        this.spinner.show();
        this.login.subscription(sub_type).subscribe((response) => {
          if (response.status==200) {
            console.log(response, "payment response");
          
            window.open(response.body.message.checkout_url, "_self");
            this.spinner.hide();
            this.getSubscriptionStatus()
            localStorage.setItem("sub_type", sub_type);
            localStorage.setItem("amount", amount);
          } else if(response.status==401) {
            this.spinner.hide();
            localStorage.setItem("sub_type", sub_type);
            localStorage.setItem("amount", amount);
            this.toastr.error("session expire!!! please login and try again", "", {
              closeButton: true,
            });
            this.router.navigate(["/login/subscription"]);
           
          } else{
            this.toastr.error(response.error.message, "", {
              closeButton: true,
            });
          }
          console.log(response, "payment response");
          
          window.open(response.body.message.checkout_url, "_self");
          this.spinner.hide();
          this.getSubscriptionStatus()
          localStorage.setItem("sub_type", sub_type);
          localStorage.setItem("amount", amount);
        });
      } else {
        // this.addpaymentCard();
        localStorage.setItem("sub_type", sub_type);
        localStorage.setItem("amount", amount);
      }
    } else {
      localStorage.setItem("sub_type", sub_type);
      localStorage.setItem("amount", amount);
      this.router.navigate(["/login/subscription"]);
    }
  }

  // addpaymentCard() {
  //   this.locker
  //     .sendRequest(
  //       "GET",
  //       "/api/subscription/create-payment-method",
  //       null,
  //       null,
  //       null,
  //       null,
  //       false
  //     )
  //     .subscribe((response) => {
  //       console.log(response, "payment meti");
  //       localStorage.setItem(
  //         "client_secret",
  //         response.body.message.client_secret
  //       );
  //       localStorage.setItem(
  //         "publishable_key",
  //         response.body.message.publishable_key
  //       );
  //       this.router.navigate(["/stripe/add-card"]);
  //     });
  // }

  unsubscribe() {
    // let bodyData = {
    //   name: this.userDetails.name,
    //   email: this.userDetails.email,
    //   type: "monthly",
    // };
    this.locker
      .sendRequest(
        "GET",
        "/api/subscription/delete",
        null,
        null,
        null,
        null,
        false
      )
      .subscribe((response) => {
        console.log(response);
        if (response.status == 200) {
          this.toastr.success("Unsubscribe Successfully!!", "", {
            closeButton: true,
          });
          this.status = null
        setTimeout(() => {
          this.getSubscriptionStatus()
        }, 1000);
          //   this.router.navigate(["/login/subscription"]);
        }
      });
  }

  checkPaymentmethod() {
    this.locker
      .sendRequest(
        "GET",
        "/api/subscription/check-payment-method",
        null,
        null,
        null,
        null,
        false
      )
      .subscribe((response) => {
        console.log(response, "payment meti");
        if (response.status == 200) {
          this.paymentCard = true;
        } else {
          this.paymentCard = false;
        }
      });
  }

  customerPortal() {
    this.locker
      .sendRequest(
        "GET",
        "/api/subscription/create-customer-portal",
        null,
        null,
        null,
        null,
        false
      )
      .subscribe((response) => {
        console.log(response, "customer portal");
        window.open(response.body.message.portal_url, "_self");
      });
  }
}
