import { Routes } from '@angular/router';
import { WebLayoutComponent } from './layouts/web-layout/web-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { PlanComponent } from './pages/plan-create/plan.component';
import { StripeComponent } from './pages/stripe/stripe.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';




export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'login/subscription',
    component:LoginComponent
  },
  {
    path:'subscription/thank-you',
    component:ThankYouComponent
  },
  {
    path:'login/subscription',
    component:LoginComponent
  },
  {
    path:'subscription',
    component:SubscriptionComponent
  },
  {
    path:'stripe/add-card',
    component:StripeComponent
  },
  {
    path:'plan',
    component:PlanComponent
  },
  {
    path: 'range',
    redirectTo: 'range',
    pathMatch: 'full',
  },
  //  {
  //   path: '',
  //   component: WebLayoutComponent,
  //   children: [
  //       {
  //     path: '',
  //     loadChildren: './layouts/web-layout/web-layout.module#WebLayoutModule'
  // }]},
  {
    path: '**',
    redirectTo: 'range'
  }
]
