import { Component, OnInit } from "@angular/core";

@Component({
    selector:'app-thanks',
    templateUrl:'thank-you.component.html',
    styleUrls:['thank-you.component.css']
})

export class ThankYouComponent implements OnInit{
    substartDate;
    ngOnInit(): void {
        this.substartDate = new Date
    }
}