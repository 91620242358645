import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/app/shared/service/local-storage.service";
import { ShotLockerService } from "src/app/shared/service/shot-locker.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class Loginservice implements OnInit {

    isLogin = true;
    errorMsg;
    showpassworderr = false;

    constructor(private toastr: ToastrService, private spinner: NgxSpinnerService, private locker: ShotLockerService, private router: Router, private local: LocalStorageService) { }

    ngOnInit(): void {

    }

    doLogin(loginData,currentPage) {
        this.spinner.show()
        let formData = new FormData();
        formData.append('email', loginData.email),
        formData.append('password', loginData.password)
      if (currentPage=="subscription") {
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/subscription/login', formData, null, null, null, false).subscribe(response => {
                // console.log(response);
                this.spinner.hide()
                if (response.status == 400 || response.status == 401) {
                    // alert("username and password is incorrect");
                    this.toastr.error("Unable to log in with provided credentials.", "", {
                        closeButton: true,
                    })
                    // document.getElementById("loginerr").style.display = "block";
                }
                if (response.status == 200) {
                    this.toastr.success("Login Successfully!! Welcome to Shot Locker Website", "", {
                        closeButton: true
                    })
                    this.local.storeAccessToken(response.body['access_token']);
                    localStorage.setItem('description',response.body['description'])
                    localStorage.setItem('started_on',response.body['started_on'])
                    localStorage.setItem('subscription_status',response.body['subscription_status'])
                    localStorage.setItem('ended_on',response.body['ended_on'])
                    observe.next(response);
                    


                } else {
                    // alert("username and password is incorrect")
                }


                //   this.router.navigate(['dashboard'])
                //  alert("Login Sucessfully")
            })
        })
      } else {
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/login', formData, null, null, null, false).subscribe(response => {
                console.log(response);
                this.spinner.hide()
                if (response.status == 400 || response.status == 401) {
                    // alert("username and password is incorrect");
                    this.toastr.error("Unable to log in with provided credentials.", "", {
                        closeButton: true,
                    })
                    // document.getElementById("loginerr").style.display = "block";
                }
                if (response.status == 200) {
                    this.toastr.success("Login Successfully!! Welcome to Shot Locker Website", "", {
                        closeButton: true
                    })
                    this.local.storeAccessToken(response.body['access_token']);
                    this.local.setlocalstorage("islogin", true)
                    observe.next(response);
                    this.isLogin = true;
                    


                } else {
                    // alert("username and password is incorrect")
                }


                //   this.router.navigate(['dashboard'])
                //  alert("Login Sucessfully")
            })
        })
      }

    }

    doSignUp(signupData) {
        this.spinner.show()
        let requestData = {
            first_name: signupData.firstName,
            last_name: signupData.lastName,
            email: signupData.email,
            password1: signupData.password,
            password2: signupData.confirmpassword
        }
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/signup', requestData, null, null, null, false).subscribe(response => {
                console.log(response);
                this.spinner.hide()
                if (response.status == 400) {
                    this.toastr.info("Failed to sign-up!! Please try again")
                    if (response.error['email'].length == 1) {
                        document.getElementById("emailerror").style.display = "block"
                    }
                    if (response.error['password1'].length == 1 || response.error['password1'].length == 2) {
                        document.getElementById('errpass').style.display = "block";
                        this.showpassworderr = true;
                        this.errorMsg = response.error['password1'];
                        console.log(this.errorMsg)
                    }
                }
                if (response.status == 201) {
                    this.toastr.info("Verification link send to your email. please verify and login to continue")

                }
                // if (response.status == 400 || response.status == 401) {
                //     // alert("username and password is incorrect")
                //     document.getElementById("login_error").style.display = "block";
                // }
                // if (response.status == 200) {

                //     this.router.navigate(['user']);
                //     // alert("Login Sucessfully");


                // } else {
                //     // alert("username and password is incorrect")
                // }

                this.local.storeAccessToken(response.body['access_token']);
                // this.local.setlocalstorage("islogin", true)
                observe.next(response);
                //   this.router.navigate(['dashboard'])
                //  alert("Login Sucessfully")
            })
        })

    }

    //  Google Login Api integration
    googleLogin(data) {
        this.spinner.show()
        let bodydata = {
            access_token: data
        }
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/google', bodydata, null, null, null, false).subscribe(response => {
                console.log(response);
                this.toastr.success("Login Successfully!! Welcome to Shot Locker Website","",{
                    closeButton:true
                })
                this.spinner.hide()
                this.local.storeAccessToken(response.body['access_token']);
                observe.next(response);
                this.router.navigate(['locker-room'])
                //  alert("Login Sucessfully")
            })
        })

    }

    passwordResetRequest(email) {
        this.spinner.show()
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/password-reset/request', email, null, null, null, false).subscribe(response => {
                observe.next(response);
                if (response.status == 200) {
                    this.toastr.info("Password reset OTP sent to your email successfully")
                }
                if (response.status == 400) {
                    this.toastr.error("User Email does not exist")
                }
                this.spinner.hide()
            })
        })
    }

    otpSubmission(bodydata) {
        this.spinner.show()
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/password-reset/confirm', bodydata, null, null, null, false).subscribe(response => {
                observe.next(response);
                this.spinner.hide();
                if (response.status == 200) {
                    this.toastr.success("Password has been reset with the new password.")
                }
                if (response.status == 400) {
                    this.toastr.error("Invalid OTP or OTP Expired")
                }
            })
        })
    }

    subscription(sub_type){
        

        let bodydata={
            // name:formData.first_name,
            // email:formData.email,
            subscription_type:sub_type
        }
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/subscription/create-subscription', bodydata, null, null, null, false).subscribe(response => {
                observe.next(response);
            })
        })
    }


    

}