import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { ComponentsModule } from './Components/component.module';
import { PageModule } from './pages/page.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebLayoutComponent } from './layouts/web-layout/web-layout.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { StripeComponent } from './pages/stripe/stripe.component';
import { PlanComponent } from './pages/plan-create/plan.component';






 
 
// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider('462870305342-0mjrm1qp0qvl7982kbo80rk58ujvasjs.apps.googleusercontent.com')
//   },
// ]);
 
// export function provideConfig() {
//   return config;
// }
 

@NgModule({
  declarations: [
    AppComponent,
    WebLayoutComponent,
    LoginComponent,
    SubscriptionComponent,
    ThankYouComponent,
    StripeComponent,
    PlanComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    HttpClientModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
