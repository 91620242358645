import { Component, OnInit } from '@angular/core';
import { Loginservice } from './pages/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {

  constructor(){

  }
  title = 'shot-locker-web';
}
