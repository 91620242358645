import { Injectable, OnInit } from "@angular/core";

import { Observable } from "rxjs";
import { ShotLockerService } from "src/app/shared/service/shot-locker.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class GolferService implements OnInit {

    imageUrl;
    fiPath: any;
    flag=false

    constructor(private toastr: ToastrService,private spinner: NgxSpinnerService,private locker: ShotLockerService) { }

    ngOnInit(): void {

    }

    getImageData(event) {
        localStorage.setItem("fileName", event.target.files[0].name)
        if (event.target.files.length > 0) {
            this.flag=true
            this.fiPath = event.target.files[0];

            // show image before uploading

            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0])
            reader.onload = (event: any) => {
                this.imageUrl = event.target.result;




            }


        }

        // this.fileToUpload =event.item(0);

    }


    getProfileDetails() {
        this.spinner.show()
        return new Observable<any>((observe) => {
            this.locker.sendRequest('GET', '/api/account/profile', null, null, null, null, false).subscribe(response => {
                console.log(response);
                if (response.status==200) {
                    this.spinner.hide();
                }
                observe.next(response);

            })
        })
    }

    // Update Profile Details

    updateProfileDetails(bodyData) {
        this.spinner.show()
        return new Observable<any>((observe) => {
            this.locker.sendRequest('PUT', '/api/account/profile/update', bodyData, null, null, null, false).subscribe(response => {

                this.spinner.hide()
                observe.next(response);
                if (response.status==200) {
                    this.toastr.success("Profile updated successfully!!!")
                }

            })
        })
    }

    updateProfilePhoto() {
        this.spinner.show()
        let bodydata = new FormData();
       
        bodydata.append(
            'photo',
            this.fiPath,
            localStorage.getItem('fileName').toString()
        );

        console.log(bodydata,"hjkhhjkhj")

        return new Observable<any>((observe) => {
            this.locker.sendRequest('PUT', '/api/account/profile-photo', bodydata, null, null, null, false).subscribe(response => {
                this.spinner.hide()
                if (response.status==200) {
                    this.toastr.success("Profile picture updated successfully!!!")
                }
                observe.next(response);

            })
        })
    }
}