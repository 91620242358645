import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setlocalstorage(Key, value) {
    localStorage.setItem(Key, value)
  }
  getlocalstorage(key) {
    return localStorage.getItem(key)
  }
  removelocalstorage(key) {
    return localStorage.removeItem(key);
  }


  storeAccessToken(jwt: string) {
    localStorage.setItem('access_token', jwt);
  }

  storeRefreshToken(jwt: string) {
    localStorage.setItem('refresh_token', jwt);
  }

  getAccessToken() {
    return localStorage.getItem('access_token')
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  // storeBothTokenExpiry(jwt: string) {
  //   localStorage.setItem('both_token_exp', jwt_decode(jwt)["expires_in"])
  // }

  storeAccessTokenExpTime(jwt: string) {
    localStorage.setItem('access_token_exp', jwt_decode(jwt)["exp"]);
  }

  storeRefreshTokenExpiry(jwt: string) {
    localStorage.setItem('refresh_token_exp', (jwt)["exp"]);
  }

  getAccessTokenExpTime(): any {
    return localStorage.getItem('access_token_exp');
  }

  getRefreshTokenExpTime(): any {
    return localStorage.getItem('refresh_token_exp');
  }
}
function jwt_decode(jwt: string) {
  throw new Error('Function not implemented.');
}

