import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ShotLockerService } from "../../shared/service/shot-locker.service";
import { Loginservice } from "../login/login.service";

@Component({
    selector:'app-plan',
    templateUrl:'plan.component.html',
    styleUrls:['plan.component.css']
})

export class PlanComponent implements OnInit {

    sub_type

    constructor(private locker:ShotLockerService,private login:Loginservice, private spinner: NgxSpinnerService,
        private router: Router,){}

    ngOnInit(): void {
        this.sub_type = localStorage.getItem('sub_type')
        this.checkPaymentmethod()
    }


    checkPaymentmethod() {
        this.locker
          .sendRequest(
            "GET",
            "/api/subscription/check-payment-method",
            null,
            null,
            null,
            null,
            false
          )
          .subscribe((response) => {
            console.log(response, "payment meti");
            if (response.status==200) {
                this.login.subscription(this.sub_type).subscribe((response) => {
                    console.log(response, "payment response");
                    window.open(response.body.message.checkout_url, "_self");
                    this.spinner.hide();
                    this.router.navigate(["subscription"]);
                  });
            } else {
                alert("something went wrong")
                this.router.navigate(["subscription"]);
            }
          });
      }
}